/* ./src/index.css or ./src/app.css */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
html {
  height: 100%;
}
body {
  margin: 0;
  background: url('./Mobile.png');
  background-size: cover;
  background-position: top;
  /* background: cover; */
  min-height: 100%;
  font-family: -apple-system, 'Varela Round';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
@media only screen and (min-width: 600px) {
  body {
    margin: 0;
    background: url('./Website.png');
    background-size: 100% auto;
    background: cover;
  }
}
